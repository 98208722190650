import React from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { theme } from "@common/src/theme/agencyModern";
import { ResetCSS } from "@common/src/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "../components/styling-wrappers/agencyModern.style";
import { DrawerProvider } from "@common/src/contexts/DrawerContext";
import Navbar from "../components/public-components/Navbar";
import Footer from "../components/public-components/Footer";
import Seo from "../components/seo";

import { graphql, Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SectionWrapper from "../components/activity-components/Highlight/highlight.style.js";
import Fade from "react-reveal/Fade";
import Heading from "@common/src/components/Heading";
import Container from "@common/src/components/UI/Container";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import Icon from "react-icons-kit";
import Layout from "../components/layout";

const DetailTemplate = (props) => {
  const getData = props.data.contentfulAktivitas;
  const pathToImage = getImage(getData.image);
  const richContent = getData.desc;
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return (
          <>
            {" "}
            <img
              className="rich-img"
              src={node.data.target.gatsbyImageData.images.fallback.src}
              alt="img"
            />
          </>
        );
      },
    },

    renderMark: {},
  };

  return (
    <ThemeProvider theme={theme}>
      <Seo
        title={getData.title}
        description={`halaman detil untuk artikel ${getData.title}`}
      />
      <ResetCSS />
      <GlobalStyle />
      <Layout>
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar origin="Aktivitas" />
            </DrawerProvider>
          </Sticky>

          <SectionWrapper>
            <Container>
              <div className="btn-container">
                <Link to={"/aktivitas"}>
                  <Icon className="icon-custom" icon={arrowLeft} />
                  <span className="back-btn">Back</span>
                </Link>
              </div>

              <Fade right delay={100}>
                <Heading as="h2" content={getData.title} />

                <div className="desc-container">
                  <p className="desc">
                    <span className="date"> {getData.createdAt} </span>
                    {getData.eventType ? "Acara" : "Artikel"}
                  </p>
                </div>

                <GatsbyImage image={pathToImage} alt="image" />
                <br />
                <div className="rich-p">
                  {richContent && renderRichText(richContent, options)}
                </div>
              </Fade>
            </Container>
          </SectionWrapper>

          <Footer />
        </ContentWrapper>
      </Layout>
    </ThemeProvider>
  );
};

export const query = graphql`
  query getInfo($slug: String) {
    contentfulAktivitas(slug: { eq: $slug }) {
      title
      slug
      eventType
      createdAt(formatString: "MMM DD, YYYY")
      image {
        gatsbyImageData(width: 800)
      }
      desc {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            gatsbyImageData
          }
          __typename
        }
      }
    }
  }
`;

export default DetailTemplate;
